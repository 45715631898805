import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { ReactSession } from '../../../lib/secure_reactsession';
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { OverviewTestDetail } from '../../themes/userq/TestResults/overview/OverviewTestDetail';
import { OverviewParticipant } from '../../themes/userq/TestResults/overview/OverviewParticipant';
import { OverviewEngagement } from '../../themes/userq/TestResults/overview/OverviewEngagement';
import { TestResultsSidebarNavigation } from './components/test_results_sidebar_navigation';
import { useState, useEffect, useRef } from "react";
import { getResearcherTestResultData, getResearcherTestResultOverviewData } from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import {
    atom,
    useRecoilState,
} from 'recoil';
import { LoadingIcon } from "../../loader/loadingIcon";
import { TestResultsHeader } from '../../themes/userq/TestResultsHeader/TestResultsHeader';
import { PieStatsBox } from './components/pie_stats_box';
import { Button } from '../../themes/userq/Button/Button';
import { Icon } from '../../themes/userq/Icon/Icon';
import { Text } from "../../themes/userq/Text/Text";
import TestSummaryModal from "../../dialog/test_summary_modal";
import { NoParticipants } from './components/no_participants';
import { ParticipantsNotAvailable } from './components/participants_not_available';
import { TreeTasksOverviewStatsBox } from './components/tree_tasks_overview_stats_box';
import { LinearAxisChartStatsBox } from './components/linear_axis_chart_stats_box';
import {renderTestTitle, roundNumber} from "../../../lib/helpers";
import { Box } from '../../themes/userq/Box/Box';
import { Helmet } from 'react-helmet';
import {ToastMessage} from "../../themes/userq/ToastMessage/ToastMessage";
import {settings} from "../../../data/settings";



export default function TestResultOverview({isSharedLink}) {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [testDetailData, setTestDetailData] = useState([]);

    const [testPrototypeData, setTestPrototypeData] = useState("");
    const [testFirstClickData, setFirstClickData] = useState("");

    const [parti, setParti] = useState({});

    const [treeTestOverview, setTreeTestOverview] = useState(null);

    const [testSummaryModal, setTestSummaryModal] = useState({
        open: false
    });

    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });

    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });
    
    const testResultOverviewState = atom({
        key: 'testresultoverview-' + test_id,
        default: ""
    });
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [participantPercentage, setParticipantPercentage] = useState(0);
    const [averageTimeSpend, setAverageTimeSpend] = useState("");
    //const [areaClosedChartData, setAreaClosedChartData] = useRecoilState(testResultOverviewState);
    const [areaClosedChartData, setAreaClosedChartData] = useState([]);
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [totalReportApprovedByAdmin, setTotalReportApprovedByAdmin] = useState(0);

    const projectState = atom({
        key: 'testresultproject-' + test_id,
        default: null
    });
    const [project, setProject] = useRecoilState(projectState);

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const getTestafterEndTest = () => {

        let token = ReactSession.get("token");

        let guest_result_token = '';

            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = '';

            if(location.pathname.substr(1, 1) === 'a'){

                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }


        getResearcherTestResultData({ test_id: test_id,guest_result_token:guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
                setTotalReportApprovedByAdmin(response.totalReportApprovedByAdmin);
            } else {

                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }

    const getTest = () => {
        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = '';

            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = '';

            if(location.pathname.substr(1, 1) === 'a'){

                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }
            

            getResearcherTestResultData({ test_id: test_id, guest_result_token:guest_result_token, admin_result_token: admin_result_token }, token).then((response) => {
                setLoading(false);

                if (response.success) {

                    if(response.workspace_project_team_member){
                        if(response.workspace_project_team_member.status!='active'){
                            navigate("/wsp/"+response.workspace_project_team_member.workspace_id+"/p/"+response.workspace_project_team_member.workspace_project_id+"/project-no-access")
                        }
                    }
                    if(response.test && response.test.workspace_project){
                        setProject(response.test.workspace_project);
                    }
                    // check test is available for sharing
                    if(location.pathname.substr(1, 1) === 'v' && response.is_share_result){
                        navigate(`/v/results/${test_id}/results-not-available`);
                    }
                    // check if guest user not validate there password
                    if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

                        navigate(`/v/results/${test_id}/password`);
                    }

                    setTest(response.test);
                    setTotalReportApprovedByAdmin(response.totalReportApprovedByAdmin)
                    getOverview();
                    // setAreaClosedChartData(response.testResultoverviewChart);


                    if (response.test.test_results_count) {
                        const totalParticipants = (response.test.test_results_count) / (response.test.participants) * 100;

                        var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                        if (totalParticipantCalculated > 100) {
                            setParticipantPercentage(100);
                        } else {
                            setParticipantPercentage(totalParticipantCalculated);
                        }

                    }

                    if (response.test.total_time_spent) {

                        var averageTimeSpendMinute = Math.floor(response.test.total_time_spent / (response.test.test_results_count * 60));
                        var averageTimeSpendSeconds = ((response.test.total_time_spent / response.test.test_results_count) % 60).toFixed(0);

                        setAverageTimeSpend(`${averageTimeSpendMinute}m ${averageTimeSpendSeconds}s`);

                        
                    }


                } else {

                    if(response.message == "Project not found.") {
                        navigate("/404");
                    } else if(response.message == "Test Record Not found") {
                        navigate("/404");
                    }else {

                        //navigate("/dashboard");

                        showError(response.message)
                    }
                    
                }
            });
        } else {

            getOverview();
            if (test.test_results_count) {
                const totalParticipants = (test.test_results_count) / (test.participants) * 100;

                var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                if (totalParticipantCalculated > 100) {
                    setParticipantPercentage(100);
                } else {
                    setParticipantPercentage(totalParticipantCalculated);
                }
            }

            if (test.total_time_spent) {

                var averageTimeSpendMinute = Math.floor(test.total_time_spent / (test.test_results_count * 60));
                var averageTimeSpendSeconds = ((test.total_time_spent / test.test_results_count) % 60).toFixed(0);

                setAverageTimeSpend(`${averageTimeSpendMinute}m ${averageTimeSpendSeconds}s`);
                
            }
        }
    };

    const getOverview = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';
        if(location.pathname.substr(1, 1) === 'a'){

            admin_result_token = ReactSession.get("admin_result_token");
        }

        getResearcherTestResultOverviewData({ test_id: test_id,guest_result_token:guest_result_token, admin_result_token: admin_result_token }, token).then((response) => {
            setSectionLoading(false);

            if (response.success) {
                setTest(response.test);
                setTotalReportApprovedByAdmin(response.totalReportApprovedByAdmin)

                setParti(response.participants);

                setAreaClosedChartData(response.testResultoverviewChart);

                setTestDetailData(response.testDetailOverviewArray);

                setTestPrototypeData(response.prototype_test_summary);
                setFirstClickData(response.first_click_test_summary);

                if (response.test.methodology == "Tree Test") {
                    setTreeTestOverview(response.tree_test_summary);
                }


                if (response.test.test_results_count) {
                    const totalParticipants = (response.test.test_results_count) / (response.test.participants) * 100;

                    var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                    if (totalParticipantCalculated > 100) {
                        setParticipantPercentage(100);
                    } else {
                        setParticipantPercentage(totalParticipantCalculated);
                    }

                }

                if (response.test.total_time_spent) {

                    var averageTimeSpendMinute = Math.floor(response.test.total_time_spent / (response.test.test_results_count * 60));
                    var averageTimeSpendSeconds = ((response.test.total_time_spent / response.test.test_results_count) % 60).toFixed(0);

                    setAverageTimeSpend(`${averageTimeSpendMinute}m ${averageTimeSpendSeconds}s`);
                    
                }


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const getPlan = () => {

        if (!planType) {
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }
    };




    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - Test Result Overview";
        // getPlan();
        getTest();

    }, []);

    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    }
    const getTotalParticipants = () => {

        var total = 0;

        for (var key in parti.gender) {
            total += parti.gender[key];
        }
        return total > 0 ? true : false;
    }

    const averageTaskTime = (timeSpend,resultCount) => {
        //const avearagetime = parseInt((timeSpend) / (resultCount));

        var averageTimeSpendMinute = Math.floor(timeSpend / (resultCount * 60));
        var averageTimeSpendSeconds = ((timeSpend / resultCount) % 60).toFixed(0);

        if(averageTimeSpendSeconds.length === 1){
            return averageTimeSpendMinute+'m 0'+ averageTimeSpendSeconds+'s';
        }else{
            return averageTimeSpendMinute+'m '+ averageTimeSpendSeconds+'s';
        }

    }


    return (
        <LayoutResearcher 
            isSharedLink={isSharedLink} 
            fixed_header_target={true} 
            isLoading={false} 
            wrapClass={"researcher-test-view-overflow create-test-data-wrapper"} 
            resultHeaderClass={"result-header"} 
            skipCheck={false} 
            extendedFooter={false} 
            activeMenu={""} 
            openSupportPanel={show_support_panel} 
            hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            
            <Helmet>
                <title>Overview {test && test.methodology ? " - "+renderTestTitle(test.methodology):""}  | UserQ</title>
            </Helmet>
            {test &&
                <div className="three-column-layout two-column-layout result">
                    
                    <TestResultsHeader project={project} isSharedLink={isSharedLink} test={test} endedOn={test.status === 'completed' ? moment.utc(test.ended_on).format('DD-MM-Y') : ''} callbackTopbar={callbackTopbar} />
                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">
                            <TestResultsSidebarNavigation
                                isSharedLink={isSharedLink}
                                methodology={(test.methodology === 'Five Seconds Test') ? "5 second test" : test.methodology}
                                selected_index={0}
                                test_id={(location.pathname.substr(1, 1) === 'v')?test_id:test.id}
                                screening={test && test.screening_questions  && test.screening_questions.length>0 ? "Yes" : "No"}
										
                            />
                        </div>
                        <div className="two-column-right-side">
                            <div className='test-result-inner-data'>
                                {sectionLoading &&
                                    <div className={'page-loader accountloading-box result-sections-loader'}>
                                        <LoadingIcon />
                                    </div>
                                }
                                {!sectionLoading &&
                                    <div className="test-result-overview-data">
                                        <div className={"test-result-header-heading mb-20 d-flex justify-content-between align-items-center"}>
                                            <Text type={"h4"}>Overview</Text>

                                            <Button type={"ghost"} label={"View test summary"} cssclass={"purple-color text-decoration-underline"}
                                                onClick={() => {
                                                    setTestSummaryModal({
                                                        open: true,
                                                        test_id: test.id
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className='test-result-overview-top-area mb-60 box-full-width'>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6">
                                                    <OverviewParticipant
                                                        language={(test.language === 'en') ? "english" : 'arabic'}
                                                        userQPanel={
                                                            (test.target_group && (test.target_group.target_type === 'random-testers' || (test.target_group.project_target_group && test.target_group.target_type === 'target-group'))) ?
                                                                test.test_results_tester_count
                                                                :
                                                                0
                                                        }
                                                        sharedLink={test.test_results_guest_count}
                                                        test={test}
                                                        totalReportApprovedByAdmin={totalReportApprovedByAdmin}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6">

                                                    <OverviewTestDetail
                                                        items={testDetailData}
                                                        methodology={''}
                                                    />
                                                </div>

                                                <div className="col-lg-6">

                                                    <OverviewEngagement
                                                        liveFrom={moment.utc(test.published_on).format('DD-MM-Y')}
                                                        avgTime={averageTimeSpend}
                                                        methodology={test.methodology}
                                                        graph={areaClosedChartData}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {test && test.target_group.target_type == 'test-links' &&
                                        <>
                                            <div className='shared-link-overview-participants'>
                                                <Text type={"h4"}>Participants overview</Text>
                                                <Box size="large" cssClasses="shared-link-overview-participants-box">
                                                <ParticipantsNotAvailable small={true} />
                                                </Box>
                                                {/* <Button

                                                 label={"Use UserQ panel to recruit participants"}
                                                 size={"large"}
                                                 onClick={()=>{}}
                                                /> */}
                                            </div>

                                            {test && test.methodology == "Tree Test"  && treeTestOverview &&
                                            <div className='result-pie-chart-wrap box-full-width mt-40'>
                                                <div className='mb-20 box-outer-heading'>
                                                    <Text type={"h4"}>Test overview</Text>
                                                </div>
                                                <div className="tasksuccess-outerbox mb-40 d-block">
                                                    <div className='row'>
                                                        <div className='col-lg-6'>
                                                            <LinearAxisChartStatsBox title={"Task success %"} size={"medium"} percentage={((treeTestOverview.overview.success_count / treeTestOverview.overview.total_count) * 100).toFixed(2)} />
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <LinearAxisChartStatsBox title={"Task success directness  %"} size={"medium"} percentage={((treeTestOverview.overview.direct_count / treeTestOverview.overview.total_count) * 100).toFixed(2)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row  mb-40">
                                                    <div className='col-md-12'>
                                                    <TreeTasksOverviewStatsBox  isSharedLink={isSharedLink} tasks={treeTestOverview.tasks} title={"By Task"} hide={false} />
                                                    </div>
                                                </div>

                                                <div className='text-center d-flex justify-content-center'>
                                                    <Button
                                                        label={"Tree testing analysis"}
                                                        size={"large"}
                                                        iconRight={
                                                            <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"} />
                                                        }
                                                        onClick={() => {
                                                            if(isSharedLink=="viewer"){
                                                                navigate("/v/results/" + test_id + "/tree-test");
                                                            }else if(isSharedLink=="admin"){
                                                                navigate("/a/results/" + test_id + "/tree-test");
                                                            }else {
                                                                navigate("/r/results/" + test.id + "/tree-test");
                                                            }
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            
                                        }
                                        </>
                                        }

                                        {test && (test.target_group.target_type !== 'test-links') && parti &&
                                            <>
                                                {
                                                    getTotalParticipants()
                                                        ?
                                                        <>
                                                            <div className='result-pie-chart-wrap box-full-width'>
                                                                <div className='mb-20 box-outer-heading'>
                                                                    <Text type={"h4"}>Participants overview</Text>
                                                                </div>
                                                                <div className="row  mb-40">
                                                                    <div className='col-lg-6'>
                                                                        <PieStatsBox data={parti.age} title={"Age"} />
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <PieStatsBox data={parti.gender} title={"Gender"} />
                                                                    </div>
                                                                </div>

                                                                <div className='text-center d-flex justify-content-center'>
                                                                    <Button
                                                                        label={"More about your participants"}
                                                                        size={"large"}
                                                                        iconRight={
                                                                            <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"} />
                                                                        }
                                                                        onClick={() => {
                                                                            if(isSharedLink=="viewer"){   
                                                                                navigate("/v/results/" + test_id + "/participants");
                                                                            }else if(isSharedLink=="admin"){   
                                                                                navigate("/a/results/" + test_id + "/participants");
                                                                            } else {
                                                                                navigate("/r/results/" + test.id + "/participants");
                                                                            }
                                                                            
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>
                                                            {test && test.methodology == "Tree Test" && treeTestOverview &&
                                                                <div className='result-pie-chart-wrap box-full-width mt-40'>
                                                                    <div className='mb-20 box-outer-heading'>
                                                                        <Text type={"h4"}>Test overview</Text>
                                                                    </div>
                                                                    <div className="tasksuccess-outerbox mb-40 d-block">
                                                                        <div className='row'>
                                                                            <div className='col-lg-6'>
                                                                                <LinearAxisChartStatsBox title={"Task success %"} size={"medium"} percentage={((treeTestOverview.overview.success_count / treeTestOverview.overview.total_count) * 100).toFixed(2)} />
                                                                            </div>
                                                                            <div className='col-lg-6'>
                                                                                <LinearAxisChartStatsBox title={"Task success directness  %"} size={"medium"} percentage={((treeTestOverview.overview.direct_count / treeTestOverview.overview.total_count) * 100).toFixed(2)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row  mb-40">
                                                                        <div className='col-md-12'>
                                                                        <TreeTasksOverviewStatsBox tasks={treeTestOverview.tasks} title={"By Task"} hide={false} />
                                                                        </div>
                                                                    </div>

                                                                    <div className='text-center d-flex justify-content-center'>
                                                                        <Button
                                                                            label={"Tree testing analysis"}
                                                                            size={"large"}
                                                                            iconRight={
                                                                                <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"} />
                                                                            }
                                                                            onClick={() => {
                                                                                if(isSharedLink=="viewer"){
                                                                                    navigate("/v/results/" + test_id + "/tree-test");
                                                                                }else if(isSharedLink=="admin"){
                                                                                    navigate("/a/results/" + test_id + "/tree-test");
                                                                                }else{
                                                                                    navigate("/r/results/" + test.id + "/tree-test");
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        <NoParticipants small={true} />
                                                }
                                            </>
                                        }
                                        

                                        {testPrototypeData && testPrototypeData.taskType === 'closed' && (testPrototypeData.resultcount > 0) &&
                                        <div className='test-result-overview-prototype-data-area mb-60 box-full-width mt-60'>

                                            <div className={'test-result-overview-prototype-data-area-header'}>
                                                <Text type={"h4"}>Test overview</Text>
                                                <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses="light-dark-text mt-20">
                                                    This is an overview to show how your testers performed on the different tasks.
                                                </Text>
                                            </div>

                                            <div className="prototype-overview-content mt-20 mb-40 prototype-result-overview-wrap p-0">
                                                <div className="row">
                                                    <div
                                                        className={`col-lg-3 col-md-6`}
                                                    >
                                                        <div className=" prototype-overview-inner-data average-click">
                                                            <Text
                                                                type={
                                                                    "body-text-1"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                                children={
                                                                    "Total tasks"
                                                                }
                                                            />

                                                            <div
                                                                className={`data-percent d-flex`}
                                                            >
                                                                <Icon
                                                                    size={
                                                                        "large"
                                                                    }
                                                                    value={
                                                                        "click"
                                                                    }
                                                                />
                                                                <Text
                                                                    type={
                                                                        "h3"
                                                                    }
                                                                    children={
                                                                        testPrototypeData.prototypeTaskCount
                                                                    }
                                                                />
                                                            </div>

                                                            <Text
                                                                type={
                                                                    "caption"
                                                                }
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                {
                                                                    testPrototypeData.prototypeTaskCount
                                                                }{" "}
                                                                {testPrototypeData.prototypeTaskCount ===
                                                                1
                                                                    ? "task"
                                                                    : "tasks"}{" "}
                                                                and{" "}
                                                                {
                                                                    testPrototypeData.prototypeTaskQuestionCount
                                                                }{" "}
                                                                follow
                                                                up
                                                                questions
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`col-lg-3 col-md-6`}
                                                    >
                                                        <div className=" prototype-overview-inner-data completion-rate">
                                                            <Text
                                                                type={
                                                                    "body-text-1"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                                children={
                                                                    "Completion rate"
                                                                }
                                                            />

                                                            <div
                                                                className={`data-percent d-flex`}
                                                            >
                                                                {/*isActive*/}
                                                                <Icon
                                                                    size={
                                                                        "large"
                                                                    }
                                                                    value={
                                                                        "success"
                                                                    }
                                                                    colorClass={
                                                                        "success-green-200-svg"
                                                                    }
                                                                />
                                                                {/*testPrototypeData.completedTaskCountPercentage*/}
                                                                <Text
                                                                    type={
                                                                        "h3"
                                                                    }
                                                                    cssClasses={
                                                                        "green-text"
                                                                    }
                                                                    children={`${roundNumber(
                                                                        testPrototypeData.successTaskCountPercentage,
                                                                        1
                                                                    )}%`}
                                                                />
                                                            </div>

                                                            <Text
                                                                type={
                                                                    "caption"
                                                                }
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                An
                                                                average
                                                                of{" "}
                                                                {roundNumber(
                                                                    testPrototypeData.successTaskCount,
                                                                    0
                                                                )}{" "}
                                                                users
                                                                per task
                                                                have
                                                                completed
                                                                the task
                                                                on the
                                                                goal
                                                                screen
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`col-lg-3 col-md-6`}
                                                    >
                                                        <div className=" prototype-overview-inner-data skip-rate">
                                                            <Text
                                                                type={
                                                                    "body-text-1"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                                children={
                                                                    "Skip rate"
                                                                }
                                                            />

                                                            <div
                                                                className={`data-percent d-flex`}
                                                            >
                                                                <Icon
                                                                    size={
                                                                        "large"
                                                                    }
                                                                    value={
                                                                        "forward"
                                                                    }
                                                                    colorClass={
                                                                        "gray-700-svg"
                                                                    }
                                                                />
                                                                <Text
                                                                    type={
                                                                        "h3"
                                                                    }
                                                                    cssClasses={
                                                                        "light-dark-text"
                                                                    }
                                                                    children={`${roundNumber(
                                                                        testPrototypeData.skipTaskCountPercentage,
                                                                        1
                                                                    )}%`}
                                                                />
                                                            </div>

                                                            <Text
                                                                type={
                                                                    "caption"
                                                                }
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                {
                                                                    testPrototypeData.skipTaskCount
                                                                }{" "}
                                                                out of{" "}
                                                                {
                                                                    testPrototypeData.resultcount
                                                                }{" "}
                                                                have
                                                                left or
                                                                given up
                                                                on your
                                                                task
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`col-lg-3 col-md-6`}
                                                    >
                                                        <div className=" prototype-overview-inner-data average-time">
                                                            <Text
                                                                type={
                                                                    "body-text-1"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                                children={
                                                                    "Average time spent"
                                                                }
                                                            />

                                                            <div
                                                                className={`data-percent d-flex`}
                                                            >
                                                                <Icon
                                                                    size={
                                                                        "large"
                                                                    }
                                                                    value={
                                                                        "time"
                                                                    }
                                                                />
                                                                <Text
                                                                    type={
                                                                        "h3"
                                                                    }
                                                                    children={`${averageTaskTime(
                                                                        testPrototypeData.prototypeTaskResultsAverageTime,
                                                                        testPrototypeData.resultcount
                                                                    )}`}
                                                                />
                                                            </div>

                                                            <Text
                                                                type={
                                                                    "caption"
                                                                }
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                The
                                                                average
                                                                time
                                                                spent on
                                                                this
                                                                task is{" "}
                                                                {averageTaskTime(
                                                                    testPrototypeData.prototypeTaskResultsAverageTime,
                                                                    testPrototypeData.resultcount
                                                                )}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='text-center d-flex justify-content-center test-result-overview-prototype-data-area-footer'>
                                                <Button
                                                    label={"Task flow analysis"}
                                                    size={"large"}
                                                    iconRight={
                                                        <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"} />
                                                    }
                                                    onClick={() => {
                                                        if(isSharedLink=="viewer"){
                                                            navigate("/v/results/" + test_id + "/prototype-test");
                                                        }else if(isSharedLink=="admin"){
                                                            navigate("/a/results/" + test_id + "/prototype-test");
                                                        }else {
                                                            navigate("/r/results/" + test.id + "/prototype-test");
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        }


                                        {testFirstClickData && (testFirstClickData.resultcount > 0) &&
                                        <div
                                            className='test-result-overview-prototype-data-area mb-60 first-click-overview box-full-width mt-60'>

                                            <div className={'test-result-overview-prototype-data-area-header'}>
                                                <Text type={"h4"}>Test overview</Text>
                                            </div>

                                            <div
                                                className='prototype-overview-content mt-20 mb-40 prototype-result-overview-wrap p-0'>

                                                <div className="row">

                                                    <div className={`col-lg-4 col-md-6`}>

                                                        <div className=' prototype-overview-inner-data average-time'>
                                                            <Text type={"h4"} children={"Average time spent"}/>

                                                            <div className={`data-percent d-flex`}>

                                                                <Icon size={'large'} value={'time'}/>
                                                                <Text type={"h1"} children={`${averageTaskTime(testFirstClickData.averageTime,testFirstClickData.resultcount)}`} />

                                                            </div>

                                                            <Text type={"caption"}>
                                                                The average time spent on this task is {averageTaskTime(testFirstClickData.averageTime,testFirstClickData.resultcount)}
                                                            </Text>

                                                        </div>
                                                    </div>



                                                </div>
                                            </div>

                                            <div
                                                className='text-center d-flex justify-content-center test-result-overview-prototype-data-area-footer'>
                                                <Button
                                                    label={"First click analysis"}
                                                    size={"large"}
                                                    iconRight={
                                                        <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"}/>
                                                    }
                                                    onClick={() => {
                                                        if(isSharedLink=="viewer"){   
                                                            navigate("/v/results/" + test_id + "/first-click");
                                                        }else if(isSharedLink=="admin"){
                                                            navigate("/a/results/" + test_id + "/prototype-test");
                                                        } else {
                                                            navigate("/r/results/" + test.id + "/first-click");
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        }

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <TestSummaryModal
                test_id={testSummaryModal.test_id}
                openModal={testSummaryModal.open}
                close={() => {
                    setTestSummaryModal({ ...testSummaryModal, open: false });
                }}
            />
            {!isSharedLink &&
            <a onClick={(e) => {
                e.preventDefault();

                setShowSupportPanel(true);

            }} href={process.env.REACT_APP_URL + "r/support"} target="_blank" className='support-button'>
                <Icon
                    value={"support"}
                    colorClass={"gray-50-svg"}
                    size={"medium"}
                />
                Support
            </a>
            }
        </LayoutResearcher>
    )
}